import { Section, Container, Content } from "./styles"
import {useState} from 'react';

export function Depoimentos() {

    const [selected, setSelected] = useState('t-3');

    const handleChange = event => {
        setSelected(event.target.value);
    }

    return (
        <Section id="feedback">
            <Container>
                <Content>
                    <div>
                        <span id="title-section">Depoimentos</span>
                        <h5 id="subtitle">Feedback dos nossos clientes</h5>
                    </div>
                    <div className="testimonial-wrapper">
                        <input type="radio" name="testimonial" id="t-1" value="t-1" checked={selected === 't-1'} onChange={handleChange} />
                        <input type="radio" name="testimonial" id="t-2" value="t-2" checked={selected === 't-2'} onChange={handleChange}/>
                        <input type="radio" name="testimonial" id="t-3" value="t-3" checked={selected === 't-3'} onChange={handleChange}/>
                        <input type="radio" name="testimonial" id="t-4" value="t-4" checked={selected === 't-4'} onChange={handleChange}/>
                        <div className="testimonials">
                            <label className="item" htmlFor="t-1">
                                <div className="testimonial-textbox">
                                    <p>
                                        O atendimento foi maravilhoso. Explicaram tudo, tiraram dúvidas e tudo ocorreu conforme foi explicado. O dinheiro 
                                        foi pago na hora. 
                                    </p>
                                </div>
                                <div className="testimonial-author">
                                    <h5>
                                        Shaiane Monique, advogada
                                    </h5>
                                    <span>
                                        Rio de Janeiro - RJ
                                    </span>
                                </div>
                            </label>
                            <label className="item" htmlFor="t-2">
                                <div className="testimonial-textbox">
                                    <p>
                                        Quero agradecer e parabenizar a equipe da PRC. Fui altamente muito bem tratada, explicaram muito bem sobre a compra do precatório. Super índico.
                                    </p>
                                </div>
                                <div className="testimonial-author">
                                    <h5>
                                        Ana Claudia Augusto da Silva
                                    </h5>
                                    <span>
                                        Rio de Janeiro - RJ
                                    </span>
                                </div>
                            </label>
                            <label className="item" htmlFor="t-3">
                                <div className="testimonial-textbox">
                                    <p>
                                        Minha experiência com essa empresa foi muito boa. Pessoas corretas, tudo foi tratado corretamente, só gratidão.
                                    </p>
                                </div>
                                <div className="testimonial-author">
                                    <h5>
                                        Sônia Maria Novaes
                                    </h5>
                                    <span>
                                        Rio de Janeiro - RJ
                                    </span>
                                </div>
                            </label>
                            <label className="item" htmlFor="t-4">
                                <div className="testimonial-textbox">
                                    <p>
                                        A minha experiência com a empresa foi a melhor possível. Atenção, compreensão, e acima de tudo 
                                        a transparência do contrato estabelecido. Tive todas as minhas dúvidas sanadas, e resolução rápida e eficaz. Só tenho 
                                        a agradecer o carinho e atenção de todos. Recomendo pela transparência e eficácia do proposto. Obrigada.
                                    </p>
                                </div>
                                <div className="testimonial-author">
                                    <h5>
                                        Cecilia Maria
                                    </h5>
                                    <span>
                                        Rio de Janeiro - RJ
                                    </span>
                                </div>
                            </label>
                        </div>
                        <div className="dots">
                            <label htmlFor="t-1"></label>
                            <label htmlFor="t-2"></label>
                            <label htmlFor="t-3"></label>
                            <label htmlFor="t-4"></label>
                        </div>
                    </div>
                </Content>
            </Container>
        </Section>
    )
}