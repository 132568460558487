import { Section, Aboutdiv, Container, Content } from "./styles";

export function PoliticaDePrivacidade() {
  return (
    <Section>
      <Container>
        <Content>
          <Aboutdiv>
            <header>
              <a href="/">Voltar para página inicial</a>
              <hr />
              <h1>Política de Privacidade</h1>
            </header>

            <main>
              <h2>1. Introdução</h2>
              <p>Bem-vindo à Política de Privacidade da PRC INVEST. Esta política descreve como coletamos, usamos, compartilhamos e protegemos suas informações pessoais de acordo com os princípios da Lei Geral de Proteção de Dados (LGPD).</p>

              <h2>2. Coleta de Informações</h2>
              <p>Coletamos informações pessoais quando você se cadastra em nosso site, preenche formulários ou nos fornece informações durante o processo de intermediação de compra de precatórios. As informações coletadas podem incluir seu nome, endereço, informações de contato e outras informações relevantes.</p>

              <h2>3. Uso das Informações</h2>
              <p>As informações coletadas são utilizadas para fins de intermediação de compra de precatórios, comunicação com você, fornecimento de serviços e melhoria de nossos processos. Não compartilhamos suas informações pessoais com terceiros sem o seu consentimento, exceto quando necessário por exigências legais.</p>

              <h2>4. Compartilhamento de Informações</h2>
              <p>Poderemos compartilhar suas informações com parceiros de negócios e prestadores de serviços que atuam em nosso nome, sempre respeitando a sua privacidade e os requisitos da LGPD.</p>

              <h2>5. Segurança e Retenção</h2>
              <p>Implementamos medidas de segurança técnicas e organizacionais para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição. Retemos suas informações pelo tempo necessário para cumprir os propósitos descritos nesta política, a menos que a lei exija ou permita um período mais longo.</p>

              <h2>6. Seus Direitos</h2>
              <p>De acordo com a LGPD, você tem o direito de acessar, corrigir, excluir e solicitar a portabilidade de suas informações pessoais. Para exercer esses direitos, entre em contato conosco através dos meios indicados na seção de Contato.</p>

              <h2>7. Contato</h2>
              <p>Se tiver alguma dúvida sobre nossa Política de Privacidade ou sobre suas informações pessoais, entre em contato conosco:</p>
              <ul>
                <li>Email: contato@prcinvest.com</li>
                <li>Telefone: (21) 97093-3038</li>
              </ul>
            </main>

            <footer>
              <p>&copy; 2023 PRC INVEST. Todos os direitos reservados.</p>
            </footer>
          </Aboutdiv>
        </Content>
      </Container>
    </Section>
  );
}
