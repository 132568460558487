import styled from "styled-components"

export const Formulario = styled.div`
    padding: 0 25px 0 25px;



    input, select, textarea {
        border: 1px solid #dfdfdf;
        font-family: "Roboto";
        font-size: 14px;
        width: 100%;
        padding: 0.9rem;
        margin: 0 0 15px 0;
        max-width: 100%;
        resize: none;
        outline: none;
        color: #828282;
        line-height: initial;
        word-break: initial;
    }
    

    #select-form{
        display: flex;


    }

    #select-form div{
        width: 50%;
        text-align: center;
        font-size: 20px;
        cursor: pointer;

    }


    #select-form div span{
        display: inline-block;
        padding: 5px;
    }


    #selected-form{
        position: relative;
        margin-bottom: 20px;
        width: 50%;
        height: 2px;
        background-color: var(--yellow-pear);
        transition: float 1s ease;
    }


    #inputs-info-pessoal {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .tamanho-input{
        width: 50%;
    }

    #button-div {
        float: right;
    }

    #input-origem-tribunal{
        display: flex;
        gap: 20px;
    }

    #politica-butao{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: flex-end;

        @media (max-width:450px) {
            justify-content: center;
            align-items: center;
        }
    }


    #politica-privacidade span a {
        color: black;
    }

    #form-button{
        font-family: "Roboto";
        background: #6c6c6b;
        color: #d4d32a;
        font-size: 12px;
        padding: 10px 26px;
        cursor: pointer;
        display: inline-block;
        border: 2px solid transparent;
        border-radius: 0;
        text-transform: uppercase;
        width: auto;
    }


    @media screen and (max-width: 520px) {
        #form-cpfcnpj {
            display: flex;
            flex-direction: column;
            padding-top: 2px;
        }

        #form-processo {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        #politica-butao{
            display: flex;
            flex-direction: column;
            gap:20px;
            margin-top: 20px;
        }
        
    }

`