import { Section, Container, Content, ContactUs, Info, Style, Stylel } from "./styles";
import { FiCheckCircle, FiMail, FiMapPin } from "react-icons/fi";
import { Form } from "../Formulario";

export function Contact() {
  return (
    <Section id="contact">
      <Container>
        <Content>
            <ContactUs>
              <div>
                <span id="fale-conosco">VENDA SEU PRECATÓRIO</span>
                <h5>DESEJA RECEBER UMA PROPOSTA PRO SEU PRECÁTORIO? <br/>ENVIE NOS O SEU CONTATO!</h5>
                <Info>
                  <div id="contatos">
                    <div className="div-contatos">
                      <FiCheckCircle style={Style} />
                      <span className="span-contact">43.067.184/0001-10</span>
                    </div>
                    <div className="div-contatos">
                      <FiMail style={Style} />
                      <span className="span-contact">contato@prcinvest.com.br</span>
                    </div>
                    <div className="div-contatos">
                      <FiMapPin style={Stylel} />
                      <span className="span-contact">Av. das Américas, 18.500, Sala 407 - RJ</span>
                    </div>
                  </div>
                </Info>
              </div>
            </ContactUs>
          <Form />
        </Content>
      </Container>
    </Section>
  );
}
