import styled from "styled-components"

export const Section = styled.section`
    background: #f7f7f7;
    padding: 120px 0;

    @media (max-width:425px){
        padding: 80px 0;
    }
    

`

export const Container = styled.div`
    max-width: 1170px;
    padding: 0 20px;
    margin: 0 auto;

`

export const Content = styled.div`
    margin-left: -15px;
    margin-right: -15px;

`

export const Aboutdiv = styled.div`
    padding: 1rem;
    width: 100%;

    @media (max-width: 500px){
        width: 100%;
        margin-bottom: 30px;
    }

    span{
        display:block;
        margin-bottom: 2rem;
        color: var(--dim-gray);
        font-size: 20px;
    }
    
    p{
        font-family: "Roboto";
        font-style:normal;
        font-weight: 400;
        font-size: 15px;
        color: #828282;
        line-height: 30px;
        
    }

`



