import { Home } from "./pages/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrivatePolicy } from './pages/politicaPrivacidade'; // Corrigi a importação

export function App() {
  
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} /> {/* Usei "path" em vez de "exact path" */}
          <Route path="/politica-de-privacidade" element={<PrivatePolicy />} /> {/* Usei "path" em vez de "exact path" */}
        </Routes>
      </Router>
    </>
  );
}
