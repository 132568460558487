import { About } from "../../src/components/About";
import { Contact } from "../../src/components/Contact";
import { Slider } from "../../src/components/Slider";
import { Depoimentos } from "../../src/components/Depoimentos";
import { Negociacao } from "../../src/components/Negociacao";
import { Header } from "../components/Header";
import { GlobalStyle } from "../styles/global";
import { Footer } from "../components/Footer";


export function Home() {
  return (
    <>
      <GlobalStyle/>
      <Header/>
      <Slider id="home"/>
      <About id="about"/>
      <Negociacao id="negotiation"/>
      <Depoimentos id="feedback"/>
      <Contact id="contact"/>
      <Footer/>
    </>
  );
}
