import logoImg from '../../assets/logo-prc2.svg'
import { Container, Content, NavBar, style } from './styles'

import { Burger } from '../Burger'
import { Menu } from '../Menu'
import { useState } from 'react'
import { FaInstagram, FaWhatsapp } from "react-icons/fa";


export function Header() {
    const [open, setOpen] = useState(false);
    return (
        <Container>
            <Content>
                <img src={logoImg} alt="logo da prc" />
                <NavBar>
                    <a href='#home'>Início</a>
                    <a href='#about' >Quem Somos</a>
                    <a href="#negotiation">Negociação</a>
                    <a href='#feedback' >Depoimentos</a>
                    <a href='#contact' >Venda seu precatório</a>
                    <a href="https://www.instagram.com/prc.invest/" target="_blank"><FaInstagram style={style} /></a>
                    <a href="https://api.whatsapp.com/send?phone=5521970933038" target="_blank"><FaWhatsapp style={style} /></a>
                </NavBar>
                <Burger open={open} setOpen={setOpen} />
                <Menu open={open} setOpen={setOpen} />
            </Content>
        </Container>
    )
}