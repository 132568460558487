import { Axios } from "../config";

export const postMail = async (mailData) => {
    try {
        const res = await Axios.post("sendMail", mailData);
        console.log(res.data);
        return { data: res.data, error: false };

    } catch (error) {
        console.log("Error in postMail:", error)
        return { data: [], error: true };
    }
};