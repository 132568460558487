import { Formulario } from "./styles";
import { useState, useRef } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postMail } from '../../lib';
import  InputMask  from 'react-input-mask';
import { IoPersonOutline } from 'react-icons/io5';
import { BsJournalBookmark } from 'react-icons/bs';
import ReCAPTCHA from "react-google-recaptcha";



export function Form() {
    const [ name, setName ] = useState('');
    const [ valueBirthdate, setValueBirthdate] = useState('');
    const [ email, setEmail] = useState('');
    const [ valueTelephone, setValueTelephone] = useState('');
    const [loading, setLoading] = useState(false);
    const [valueCPFCNPJ, setValueCPFCNPJ] = useState('');
    const [valueProcesso, setValueProcesso] = useState('');
    const [valueOrigemPrecatorio, setValueOrigemPrecatorio] = useState('');
    const [valueTribunal, setValueTribunal] = useState('');
    const [valueObservacoes, setValueObservacoes] = useState('');
    const [selectedFormFloat, setSelectedFormFloat] = useState('left');
    const [selectedForm, setSelectedForm] = useState(null);

    const recaptcha = useRef();

    const handleCPFCNPJChange = (event) => {
        const inputValue = event.target.value.replace(/\D/g, '');

        let formattedValue = '';

        if (inputValue.length <= 11) {
            formattedValue = inputValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else {
            formattedValue = inputValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

        setValueCPFCNPJ(formattedValue);
    };

    const handleTelephone = (event) => {
        let formattedValue = '';
        const numericValue = event.target.value.replace(/^(\+55)|\D/g, '');
        formattedValue = numericValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        setValueTelephone(formattedValue);
    };

    const handleBirthdate = (event) => {
        let formattedValue = '';
        const numericValue = event.target.value.replace(/\D/g, '');
        formattedValue = numericValue.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
        setValueBirthdate(formattedValue);
    }
    
    async function handleNewMessage(event){
        event.preventDefault()

        const captchaValue = recaptcha.current.getValue();

        if(!captchaValue) {
            return toast.error("Por favor verifique o reCAPTCHA!")
        }

        if (selectedFormFloat === 'left') {
            if (!name || !email || !valueTelephone || !valueBirthdate){
                return toast.error('Preencha os campos de CPF/CNPJ, nome, data de nascimento, email e telefone.')
            }
        }

        if (selectedFormFloat === 'right') {
            if (!name || !email || !valueTelephone || !valueBirthdate || !valueProcesso || !valueTribunal || valueOrigemPrecatorio === "") {
                return toast.error('Preencha os campos de CPF/CNPJ, nome, data de nascimento, email, telefone, número do processo, origem do precatório e tribunal.')
            }
        }

        try {
            const form = new FormData();
            form.append("CpfOuCnpj", valueCPFCNPJ)
            form.append("Nome", name);
            form.append("DataNascimento", valueBirthdate);
            form.append("Email", email);
            form.append("Telefone", valueTelephone);
            form.append("Observacoes", valueObservacoes);
            
            if (selectedFormFloat === 'right') {
                form.append("NumeroProcesso", valueProcesso);
                form.append("Origem", valueOrigemPrecatorio);
                form.append("Tribunal", valueTribunal);
            }

            form.append("tipoForm", selectedFormFloat === 'left' ? "1" : "2");
            
            const cookieValue = "cookieFormulario";
            const cookieAttributes = selectedFormFloat === 'left'
            ? 'SameSite=Strict; Secure'
            : 'SameSite=Strict; Secure;';

            document.cookie = `YourCookieName=${cookieValue}; ${cookieAttributes}`;

            setLoading(true);
            window.
            dataLayer.push({'event':'formulario'});
            
            toast.success('Enviado com sucesso!')
            recaptcha.current.reset();
            setName('');
            setValueBirthdate('');
            setEmail('');
            setValueTelephone('');
            setValueCPFCNPJ('');
            setValueProcesso('');
            setValueOrigemPrecatorio('');
            setValueTribunal('');
            setValueObservacoes('');  
            const resp = await postMail(form);
            
        } catch (err){
            setLoading(false);
            toast.error(
                err.response && err.response.data.message ? err.response.data.message : err.message
            )

        }
    }

    const handleSelectedForm = (floatValue) => {
        setSelectedFormFloat(floatValue);
        setSelectedForm(null);

    }

    return (
        <Formulario>
            <ToastContainer position='top-right' limit={1}/>
            <div id="select-form" >
                <div id="form-cpfcnpj" onClick={() => handleSelectedForm('left')}>
                    <span><IoPersonOutline style={{marginBottom: "5px"}}></IoPersonOutline></span>
                    <span>CPF/CNPJ</span>
                </div>
                <div id="form-processo" onClick={() => handleSelectedForm('right')} >
                    <span><BsJournalBookmark style={{marginBottom: "5px"}}></BsJournalBookmark></span>
                    <span>Número do Processo</span>
                </div>
            </div>
            <div id="selected-form" style={{float: selectedFormFloat}} >
            </div>
            <form action='/api/sendMail' onSubmit={handleNewMessage} method="POST" encType="multipart/form-data">
                <div id="form">
                    {selectedFormFloat === 'right' && (
                        <div>
                            <input type="text" placeholder="Número do Processo *" maxLength={20} value={valueProcesso} onChange={event => setValueProcesso(event.target.value)}/>
                            <div id="input-origem-tribunal">
                            <select value={valueOrigemPrecatorio} onChange={event => setValueOrigemPrecatorio(event.target.value)}>
                                <option value="">Selecione a Origem do Precatório *</option>
                                <option value="federal">Federal</option>
                                <option value="estadual">Estadual</option>
                                <option value="municipal">Municipal</option>
                            </select>
                                <input type="text" placeholder="Tribunal *" value={valueTribunal} onChange={event => setValueTribunal(event.target.value)}/>
                            </div>
                        </div>
                    )}
                    <div id="input-cpf-cnpj">
                        <input type="text" placeholder="Digite seu CPF/CNPJ *" maxLength={18} value={valueCPFCNPJ} onChange={handleCPFCNPJChange}/>
                    </div>
                    <div id="inputs-info-pessoal">
                        <div className="tamanho-input">
                            <input type="text" placeholder="Digite seu nome completo *" name="name" value={name} onChange={event => setName(event.target.value)}/>
                            <InputMask
                                mask="99/99/9999"
                                value={valueBirthdate}
                                type="text"
                                placeholder="Digite sua data de nascimento *"
                                name="birthdate"
                                onChange={handleBirthdate}
                            />
                        </div>
                        <div className="tamanho-input">
                            <input type="text" placeholder="Digite seu E-mail *" name="email" value={email} onChange={event => setEmail(event.target.value)} />
                            <InputMask
                                mask="+55 (99)99999-9999"
                                type="tel"
                                placeholder="(DDD) 99999-9999 *"
                                name="phone"
                                value={valueTelephone}
                                onChange={handleTelephone}
                            />  
                        </div>
                    </div>
                    <div>
                        <textarea name="observacoes" id="input-observacoes"  rows="3" placeholder="Tem alguma observação que gostaria de deixar ?" value={valueObservacoes} onChange={event => setValueObservacoes(event.target.value)}></textarea>
                    </div>
                    <ReCAPTCHA ref={recaptcha} sitekey='6Lfl7SUpAAAAAHIYf2nGse7SE3Zj3m4ghhi1bGmr' />
                    <div id="politica-butao">
                        <div id="politica-privacidade">
                            <span className="politica">Ao informar meus dados, eu concordo com a <a href="/politica-de-privacidade" target="_blank">Política de Privacidade.</a></span>
                        </div>
                        <div id="button-div">
                            <button id="form-button">Enviar Mensagem</button>
                        </div>
                    </div>

                </div>
            </form>
        </Formulario>
    )
}
