import { StyledMenu } from "./styles";
import { bool, func } from 'prop-types';

export function Menu({ open, setOpen }) {
  const handleLinkClick = () => {
    setOpen(false); // Close the menu when a link is clicked
  };

  return (
    <StyledMenu open={open}>
        <span role="img" aria-label="Home"></span>
        <a href="#home" onClick={handleLinkClick}>Início</a>
        <span role="img" aria-label="Quem Somos"></span>
        <a href="#about" onClick={handleLinkClick}>Quem Somos</a>
        <span role="img" aria-label="Negociação"></span>
        <a href="#negotiation" onClick={handleLinkClick}>Negociação</a>
        <span role="img" aria-label="Contato"></span>
        <a href="#contact" id="button-sell" onClick={handleLinkClick}>Venda seu precatório</a>
    </StyledMenu>
  );
}


Menu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
}
